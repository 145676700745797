import styled from 'styled-components'
import { border } from 'styled-system'

import { Box } from '../../elements'
import { componentHeights as heights } from '../../themes'

const Container = styled(Box)({
  border,
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%'
})

Container.defaultProps = {
  bg: 'base',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'primary',
  maxHeight: [heights.modalMax.mobile, null, heights.modalMax.desktop],
  position: 'fixed',
  width: ['90%', '70%', null, '60%'],
  zIndex: '1'
}

export default Container
